export const mapPortfolio = (portfolio) => {
    var _a, _b, _c, _d, _e, _f;
    return ({
        id: portfolio.id,
        // don`t know would it be blob or url ?
        logo: `${((_a = portfolio.profile.profilePicture) === null || _a === void 0 ? void 0 : _a.thumbnail) || ''}`,
        profile_id: portfolio.profile.id,
        chat_activity: (_b = portfolio.portfoliocommentSet) === null || _b === void 0 ? void 0 : _b.length,
        copiers_count: ((_c = portfolio.copyingSet) === null || _c === void 0 ? void 0 : _c.length) || 100,
        monthly_following_fee: portfolio.monthlyFee,
        name: portfolio.name,
        viewed: (_d = portfolio.portfolioviewSet) === null || _d === void 0 ? void 0 : _d.length,
        owner_profile_id: portfolio.profile.id,
        // TODO need to discuss
        parent_exchange_id: ((_e = portfolio.exchangeConnectionPortfolio) === null || _e === void 0 ? void 0 : _e.length) ?
            portfolio.exchangeConnectionPortfolio[0].id : '0',
        referral_asset: '',
        // TODO add field after readiness on backend
        favorited_count: 0,
        shared_count: 0,
        min_balance: 0,
        graph_data: [],
        min_balance_currency: '$',
        minimum_balance_type: 'fifth_part',
        overall_difference: 0,
        past_week_difference: 20.55,
        past_month_difference: 15.81,
        past_ninety_days_difference: 18.71,
        whole_period_days_count: 150,
        traders_balance: 250,
        traders_balance_currency: '$',
        monthly_following_fee_currency: '$',
        past_month_start_date: '2023-11-15T13:09:56.276376+00:00',
        past_month_end_date: '2023-10-15T14:09:56.276376+00:00',
        followers: {
            count: Number((_f = portfolio.profile) === null || _f === void 0 ? void 0 : _f.followersCount),
            average_followers_monthly_return: 15.22,
            average_followers_monthly_return_first: 16.00,
            average_followers_monthly_return_last: 15.00,
            preview_followers: [],
        },
        type: 'Spot',
        user_subscription_summary: {
            queue_position: 1,
            duration: 14,
            past_month_difference: 10,
            unrealized_pl_value: 14.63,
            unrealized_pl_percentange: 4.8,
            unrealized_pl_currency: '$',
            unrealized_pl_trend: 'up',
            realized_pl_value: 1424.23,
            realized_pl_currency: '$',
            realized_pl_trend: 'up',
            realized_pl_percentange: 5.63,
            subscription_date: new Date('December 17, 2023'),
        },
        visibility: 'public',
        is_archived: false,
        is_in_assessment_review: false,
        has_error: false,
        profile: {
            id: '1',
            nickname: 'tony_cash',
            type: 'TRADER',
            publicName: 'Tony Cash',
        },
    });
};
const mapPortfoliosList = ({ allPortfolios, }) => allPortfolios === null || allPortfolios === void 0 ? void 0 : allPortfolios.map(mapPortfolio);
export default mapPortfoliosList;
