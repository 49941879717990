var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice, } from '@reduxjs/toolkit';
import { IsDefined } from '@utils/Helpers';
import portfolioApi from '../index';
const SliceName = 'portfolioSubscription';
const subscribe = createAsyncThunk(`${SliceName}/subscribe`, (arg_1, _a) => __awaiter(void 0, [arg_1, _a], void 0, function* (arg, { getState, dispatch, rejectWithValue }) {
    const { portfolio, subscriptionData } = getState()[SliceName];
    if (!IsDefined(portfolio) || !IsDefined(subscriptionData)) {
        // TODO: handle
        return rejectWithValue(['unknown']);
    }
    const res = yield dispatch(portfolioApi.endpoints.startCopying.initiate({
        portfolioId: portfolio.id,
        body: subscriptionData,
    }));
    if ('data' in res) {
        // eslint-disable-next-line consistent-return
        return;
    }
    return rejectWithValue(['unknown']);
}));
const PortfolioSubscriptionSlice = createSlice({
    name: SliceName,
    initialState: {
        portfolio: undefined,
        isUserSubscribed: false,
        subscriptionData: undefined,
        completedSteps: [],
        stepsInEdit: [],
        errors: [],
        isLoading: false,
    },
    reducers: {
        setPortfolioForSubscription: (state, action) => {
            state.portfolio = action.payload;
            state.isUserSubscribed = !!state.portfolio.user_subscription_summary;
            state.subscriptionData = undefined;
            state.completedSteps = []; // TODO: get completed steps from users data
        },
        startSubscriptionProccess: (state) => {
            // TODO: remove this reducer?
            state.subscriptionData = {};
        },
        submitStep: (state, action) => {
            if (!state.completedSteps.includes(action.payload)) {
                state.completedSteps.push(action.payload);
            }
        },
        editStep: (state, action) => {
            if (!state.stepsInEdit.includes(action.payload)) {
                state.stepsInEdit.push(action.payload);
            }
        },
        cancelEditStep: (state, action) => {
            const index = state.stepsInEdit.indexOf(action.payload);
            if (index !== -1) {
                state.stepsInEdit.splice(index, 1);
            }
        },
        selectExchange: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.exchangeId = action.payload;
            }
        },
        selectInvestAmount: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.investAmount = action.payload;
            }
        },
        selectStopCopyingThreshould: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.stopCopyingThreshould = action.payload;
            }
        },
        setSubscriptionPrice: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.subscriptionPrice = action.payload;
            }
        },
        setQueuePosition: (state, action) => {
            if (state.subscriptionData) {
                state.subscriptionData.queuePosition = action.payload;
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(subscribe.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(subscribe.rejected, (state, action) => {
            state.isLoading = false;
            if (IsDefined(action.payload)) {
                state.errors = action.payload;
            }
        });
        builder.addCase(subscribe.fulfilled, (state) => {
            state.isLoading = false;
            state.isUserSubscribed = true;
        });
    },
});
export { SliceName, subscribe, };
export const { setPortfolioForSubscription, startSubscriptionProccess, submitStep, editStep, cancelEditStep, selectExchange, selectInvestAmount, selectStopCopyingThreshould, setSubscriptionPrice, setQueuePosition, } = PortfolioSubscriptionSlice.actions;
export default PortfolioSubscriptionSlice.reducer;
